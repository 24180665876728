import React, { useState, useEffect } from 'react';
import { getOnboarding, getQuestionSubmit } from '../../../api/security/index';

import { StyledSeccion, StyledSectionContent } from './onboarding.styles';
import CustomTour from '../onboarding/stepOnboarding';
import SwipeableCard from '../game/SwipeableCard';
import {
  gtmSecurityQuestionFalse,
  gtmSecurityQuestionTrue,
} from '../../../tools/datalayers';

interface Icon {
  url: string;
}

interface HomeData {
  sure: number;
  attempts: number;
  points: number;
  avatar: string;
}

interface Fold {
  homeData: HomeData;
  backgroundCard: {
    url: string;
  };
  correctAnswers: {
    url: string;
  };
  incorrectAnswers: {
    url: string;
  };
  question: {
    questions: string;
  };
  questiondemo: {
    id: string;
    questions: string;
  };
}

interface BackgroundImgPage {
  url: string;
}

interface BackgroundImgPageMobile {
  url: string;
  alternativeText: string;
  caption: string;
}

interface MenuItem {
  iconOff: Icon;
  iconOn: Icon;
  page: {
    slug: string;
  };
}

interface Content {
  folds: Fold[];
  menu: MenuItem[];
  backgroundImgPage: BackgroundImgPage;
  backgroundImgPageMobile: BackgroundImgPageMobile;
  backgroundPage: string; // Añadir esta propiedad
  id: string;
}

interface Data {
  content: Content[];
}

interface TourStep {
  selector: string;
  content: {
    labelstep: string;
    description: string;
    icon: {
      url: string;
    };
  };
}

export const OnBoarding = () => {
  const [data, setData] = useState<Data | null>(null);
  const [insuranceCount, setInsuranceCount] = useState(0);
  const [attempts, setAttempts] = useState(0);
  const [menu, setMenu] = useState<Data | null>(null);
  const [isTourComplete, setIsTourComplete] = useState(false);
  const [timeLeft, setTimeLeft] = useState(120);
  const [initialTime, setInitialTime] = useState(120);
  const [hasAnswered, setHasAnswered] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [tourSteps, setTourSteps] = useState<TourStep[]>([]);
  const [isInteractionAllowed, setIsInteractionAllowed] = useState(false);
  const [isTourActive, setIsTourActive] = useState(true);
  const [timerStartTime, setTimerStartTime] = useState<number | null>(null);

  const handleTourStepChange = (step: number) => {
    setCurrentStep(step);
  };

  const getStepClass = () => {
    if (!isTourComplete) {
      return `step-${currentStep + 1}`;
    }
    return '';
  };

  const base = process.env.REACT_APP_APP_URL;
  const baseApi = process.env.REACT_APP_API_BASE_URL;

  const currentPath = window.location.pathname.replace(/^\/+/, '');

  const ENCRYPTION_KEY = 'securitygame'; // Cambia esto por una clave segura

  const simpleEncrypt = (text: string): string => {
    return text
      .split('')
      .map((char) =>
        String.fromCharCode(char.charCodeAt(0) ^ ENCRYPTION_KEY.charCodeAt(0)),
      )
      .join('');
  };

  const simpleDecrypt = (encryptedText: string): string => {
    return simpleEncrypt(encryptedText); // La misma función sirve para encriptar y desencriptar
  };

  const getSelector = (index: number) => {
    switch (index) {
      case 0:
        return '.content__header-container';
      case 1:
        return '.content__game-container';
      case 2:
        return '.content__time';
      case 3:
        return '.menu__content';
      default:
        return '';
    }
  };

  useEffect(() => {
    const fetchOnboardingData = async () => {
      try {
        const tokenJwt = localStorage.getItem('myAccessToken') || '';
        const { data } = await getOnboarding(tokenJwt);
        const response = await getOnboarding(tokenJwt);

        setData(data);
        setInsuranceCount(data?.content[0]?.folds[0]?.homeData?.sure);
        setAttempts(data?.content[0]?.folds[0]?.homeData?.attempts);
        setMenu(data?.content[0]?.menu);

        localStorage.setItem(
          'questionName',
          data?.content[0]?.folds[0]?.questiondemo?.questions,
        );

        if (response && response.data && response.data.content) {
          setData(response.data);
          const content = response.data.content[0];
          if (
            content &&
            content.folds &&
            content.folds[0] &&
            content.folds[0].step
          ) {
            const steps = content.folds[0].step.map(
              (step: any, index: number) => ({
                selector: getSelector(index),
                content: {
                  labelstep: step.labelstep,
                  description: step.description,
                  icon: {
                    url: `${baseApi}${step.icon.url}`,
                  },
                },
              }),
            );

            setTourSteps(steps);
          } else {
            console.error('No step data found in the response');
          }
        } else {
          console.error('Invalid response structure:', response);
        }

        const questionId = data?.content[0]?.folds[0]?.questiondemo?.id;
        const questionTime = data?.content[0]?.timeQuestion || 120;

        const savedTimeEncrypted = localStorage.getItem(
          `timeLeft_${questionId}`,
        );
        const savedInitialTimeEncrypted = localStorage.getItem(
          `initialTime_${questionId}`,
        );

        if (savedTimeEncrypted && savedInitialTimeEncrypted) {
          setTimeLeft(parseInt(simpleDecrypt(savedTimeEncrypted), 10));
          setInitialTime(
            parseInt(simpleDecrypt(savedInitialTimeEncrypted), 10),
          );
          setHasAnswered(false);
        } else {
          setTimeLeft(questionTime);
          setInitialTime(questionTime);
          localStorage.setItem(
            `timeLeft_${questionId}`,
            simpleEncrypt(questionTime.toString()),
          );
          localStorage.setItem(
            `initialTime_${questionId}`,
            simpleEncrypt(questionTime.toString()),
          );
          setHasAnswered(false);
        }
      } catch (error) {
        console.error('Error fetching onboarding data:', error);
      }
    };

    fetchOnboardingData();
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isTimerRunning && timeLeft > 0 && !hasAnswered) {
      setIsInteractionAllowed(true);
      timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          const newTime = prevTime - 1;
          const questionId = data?.content[0]?.folds[0]?.questiondemo?.id;
          if (questionId) {
            localStorage.setItem(
              `timeLeft_${questionId}`,
              simpleEncrypt(newTime.toString()),
            );
          }
          return newTime;
        });
      }, 1000);
    } else if (timeLeft === 0 && !hasAnswered) {
      setIsInteractionAllowed(false);
      const questionId = data?.content[0]?.folds[0]?.questiondemo?.id;
      if (questionId && initialTime !== null && timerStartTime !== null) {
        const timeElapsed = Math.floor((Date.now() - timerStartTime) / 1000);
        setHasAnswered(true);
        SubmitQuestion(questionId, false, timeElapsed, true);
      }
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [
    isTimerRunning,
    timeLeft,
    data,
    initialTime,
    hasAnswered,
    timerStartTime,
  ]);

  const handleTourComplete = () => {
    setIsTourComplete(true);
    setIsTimerRunning(true);
    setTimerStartTime(Date.now()); // Guardamos el tiempo exacto cuando comienza el temporizador
    setIsTourActive(false);
  };

  const handleTourStart = () => {
    setIsTourActive(true); // Activar el tour
  };

  // Efecto para monitorear cambios en isTimerRunning
  useEffect(() => { }, [isTimerRunning]);

  const getAttemptsImages = (attempts: number) => {
    const totalAttempts = 3;
    let images = [];
    for (let i = 0; i < totalAttempts; i++) {
      images.push(
        i < attempts
          ? `${base}/security/icon-heart-active.svg`
          : `${base}/security/icon-heart.svg`,
      );
    }
    return images;
  };

  const getInsuranceImages = (count: number) => {
    const totalInsurance = 3;
    let images = [];
    for (let i = 0; i < totalInsurance; i++) {
      images.push(
        i < count
          ? `${base}/security/icon-security-active.svg`
          : `${base}/security/icon-security.svg`,
      );
    }
    return images;
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''
      }${seconds}`;
  };

  const SubmitQuestion = async (
    questionId: string,
    answer: boolean,
    time: number,
    isDemo: boolean,
  ) => {
    const tokenJwt = localStorage.getItem('myAccessToken') || '';
    try {
      // Asegurarse de que el tiempo sea un número positivo
      const safeTime = Math.max(0, time);
      const { data } = await getQuestionSubmit(
        tokenJwt,
        questionId,
        answer,
        safeTime,
        isDemo,
      );
      localStorage.setItem('myEndpoint', data?.endpoint);
      localStorage.removeItem(`timeLeft_${questionId}`);
      localStorage.removeItem(`initialTime_${questionId}`);
      setHasAnswered(true);
      if (data?.endpoint) {
        const fullUrl = `${window.location.origin}/${data.endpoint}`;
        window.location.href = fullUrl;
      }
    } catch (error) {
      console.error('Error submitting question:', error);
    }
  };
  const handleSwipe = async (isCorrect: boolean) => {
    if (!hasAnswered && isInteractionAllowed && timerStartTime !== null) {
      const questionId = data?.content[0]?.folds[0]?.questiondemo?.id;
      if (questionId) {
        const timeElapsed = Math.floor((Date.now() - timerStartTime) / 1000);
        setHasAnswered(true);
        setIsTimerRunning(false);
        await SubmitQuestion(questionId, isCorrect, timeElapsed, true);
      }
    }
  };

  const handleMenuClick = (slug: string) => {
    if (hasAnswered) {
      const fullUrl = `${window.location.origin}/${slug}`;
      window.location.href = fullUrl;
    }
  };

  // Función para prevenir la navegación
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (!hasAnswered) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasAnswered]);
  const isMobile = () => {
    return window.innerWidth <= 1000; // Ajusta este valor según tus necesidades
  };

  useEffect(() => {
    const body = document.body;
    if (isTourActive) {
      // Desactivar scroll
      const scrollY = window.scrollY;
      body.style.position = 'fixed';
      body.style.top = `-${scrollY}px`;
      body.style.width = '100%';
    } else {
      // Reactivar scroll
      const scrollY = body.style.top;
      body.style.position = '';
      body.style.top = '';
      body.style.width = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }

    // Limpiar efecto
    return () => {
      body.style.position = '';
      body.style.top = '';
      body.style.width = '';
    };
  }, [isTourActive]);

  return (
    <>
      <StyledSeccion
        className={`page game ${getStepClass()}`}
        bgColor={data?.content[0]?.backgroundPage}
      >
        {!isTourComplete && tourSteps.length > 0 && (
          <CustomTour
            steps={tourSteps}
            onComplete={handleTourComplete}
            onStart={handleTourStart}
            onStepChange={handleTourStepChange}
          />
        )}
        <picture className="content__bg">
          <source
            srcSet={`${baseApi}${data?.content[0]?.backgroundImgPage?.url}`}
            media="(min-width: 780px)"
          />
          <img
            className="ranking__img"
            alt={data?.content[0]?.backgroundImgPageMobile?.alternativeText}
            title={data?.content[0]?.backgroundImgPageMobile?.caption}
            src={`${baseApi}${data?.content[0]?.backgroundImgPageMobile?.url}`}
          />
        </picture>
        <div className="content__header">
          <div className="content__header-container">
            <div className="content__header-content">
              <div className="content__header-top">
                <div className="content__userinfo-item">
                  <div>
                    {getInsuranceImages(insuranceCount).map((imgSrc, index) => (
                      <img
                        key={index}
                        src={imgSrc}
                        alt={`Seguro ${index + 1}`}
                      />
                    ))}
                  </div>
                </div>
                <div className="content__userinfo-item">
                  <div>
                    {getAttemptsImages(attempts).map((imgSrc, index) => (
                      <img
                        key={index}
                        src={imgSrc}
                        alt={`Intento ${index + 1}`}
                      />
                    ))}
                  </div>
                </div>
                <div className="content__userinfo-item">
                  <div>
                    <img src={`${base}/security/icon-coin.svg`} alt="" />
                    <p className="content__userinfo-label">
                      {data?.content[0]?.folds[0]?.homeData?.points}
                    </p>
                  </div>
                </div>
                <div className="content__userinfo-item item-mobil">
                  <img
                    src={`${baseApi}${data?.content[0]?.folds[0]?.homeData?.avatar}`}
                    alt=""
                  />
                </div>
              </div>
              <div className="content__header-bottom">
                <div className="content__header-bottom-one">
                  <p>Desliza para contestar</p>
                  <p>Pregunta de Prueba</p>
                </div>
                <div className="content__header-bottom-two">
                  <p>Nivel Básico</p>
                </div>
              </div>
            </div>
            <div className="content__header-content-img">
              <img
                src={`${baseApi}${data?.content[0]?.folds[0]?.homeData?.avatar}`}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="content__game">
          {isMobile() ? (
            <SwipeableCard
              content={
                data?.content[0]?.folds[0]?.questiondemo?.questions || ''
              }
              onSwipe={handleSwipe}
              backgroundImage={`${baseApi}${data?.content[0]?.folds[0]?.backgroundCard?.url}`}
              correctImageUrl={`${baseApi}${data?.content[0]?.folds[0]?.correctAnswers?.url}`}
              incorrectImageUrl={`${baseApi}${data?.content[0]?.folds[0]?.incorrectAnswers?.url}`}
            />
          ) : (
            <div className="content__game-container">
              <StyledSectionContent
                className="content__game-content"
                backgroundImage={`${baseApi}${data?.content[0]?.folds[0]?.backgroundCard?.url}`}
              >
                <p>{data?.content[0]?.folds[0]?.questiondemo?.questions}</p>
              </StyledSectionContent>
              <div className="input-container correct">
                <img
                  src={`${baseApi}${data?.content[0]?.folds[0]?.correctAnswers?.url}`}
                  alt="Input 1"
                  className="input-image"
                />
                <input
                  type="checkbox"
                  name="true"
                  className="input-field"
                  onChange={(e) => {
                    if (e.target.checked) {
                      handleSwipe(true);
                      gtmSecurityQuestionTrue(
                        data?.content[0]?.folds[0]?.questiondemo?.questions ||
                        '',
                      );
                    }
                  }}
                />
              </div>
              <div className="input-container incorrect">
                <img
                  src={`${baseApi}${data?.content[0]?.folds[0]?.incorrectAnswers?.url}`}
                  alt="Input 2"
                  className="input-image"
                />
                <input
                  type="checkbox"
                  name="false"
                  className="input-field"
                  onChange={(e) => {
                    if (e.target.checked) {
                      handleSwipe(false);
                      gtmSecurityQuestionFalse(
                        data?.content[0]?.folds[0]?.questiondemo?.questions ||
                        '',
                      );
                    }
                  }}
                />
              </div>
            </div>
          )}
          <div className="content__time">
            <p className="text-time" data-testid="timer-text">{formatTime(timeLeft)}</p>
          </div>
        </div>

        {menu && (
          <div className="menu">
            <div className="menu__container">
              <div className="menu__content">
                {Array.isArray(menu) &&
                  menu?.map((item, index) => (
                    <div key={index}>
                      <img
                        src={`${baseApi}${currentPath === item?.page?.slug
                          ? item?.iconOn?.url
                          : item?.iconOff?.url
                          }`}
                        alt={item?.page?.slug}
                        className={`item-avatars-img ${currentPath === item?.page?.slug ? 'active' : ''
                          } ${!hasAnswered ? 'disabled' : ''}`}
                        onClick={() => handleMenuClick(item?.page?.slug)}
                        style={{
                          opacity: hasAnswered ? 1 : 0.5,
                          cursor: hasAnswered ? 'pointer' : 'not-allowed',
                        }}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      </StyledSeccion>
    </>
  );
};
